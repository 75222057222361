import { Link } from "gatsby"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React from "react"

const SiteHeader = styled.header`
  display: flex;
  justify-content: center;
  height: 5vh;
`

const Content = styled.div`
  background: #89aee1;
  border-radius: 0 0 0.7em 0.7em;
  max-width: 860px;
  padding: 0 1.0875rem;
  font-size: 1.2rem;
`

const ContentText = styled.p`
  height: 100%;

  ::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
`

const NavLink = styled(Link)`
  font-family: "Lato";
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #e9f259;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const GitHubLink = styled.a`
  font-family: "Lato";
  color: black;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #e9f259;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  :hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const HomeLink = styled(NavLink)`
  margin-left: 0;
`

const Header = ({ siteTitle }) => (
  <SiteHeader>
    <Content>
      <ContentText>
        <HomeLink to="/">{siteTitle}</HomeLink>
        <NavLink to="/blog">Blog</NavLink>
        <GitHubLink href="https://www.linkedin.com/in/karl-svensson/">
          LinkedIn
        </GitHubLink>
      </ContentText>
    </Content>
  </SiteHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
